import { IAddCreditsRequest, IAddCreditsResponse } from 'contracts/requests/IAddCreditsRequest';
import { IMakeDefaultCardResponse } from 'contracts/requests/IMakeDefaultCardResponse';
import { IMessageOnlyResponse } from 'contracts/requests/IMessageOnlyResponse';
import { IPaymentMethodRequest } from 'contracts/requests/IPaymentMethodRequest';
import { IRetrievePaymentDetailsResponse } from 'contracts/requests/IRetrievePaymentDetailsResponse';
import { ITransactionListResponse } from 'contracts/requests/ITransactionListResponse';

import { IUserProfileResponse } from 'contracts/requests/IUserProfileResponse';
import { TransactionData } from 'contracts/spotdif/TransactionData';
import {
    TAG_GET_TRANSACTION_TABLE,
    TAG_GET_USER_CARD,
    TAG_GET_USER_PROFILE,
    TAG_TRANSACTION_TABLE,
    TAG_USER_CARD,
    TAG_USER_PROFILE,
} from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';
import { IXeroInvoiceGenerationRequest } from 'contracts/requests/interface IXeroInvoiceGenerationRequest';

interface TransactionQuery {
    id?: string;
}

const creditAndBillingApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getTransactionsTableData: builder.query<TransactionData[], TransactionQuery>({
            providesTags: [
                {
                    type: TAG_TRANSACTION_TABLE,
                    id: TAG_GET_TRANSACTION_TABLE,
                },
            ],

            query: (queryArgs) => {
                return {
                    url: `/transactions${queryArgs?.id ? '/' + queryArgs?.id : ''}`,
                    method: 'get',
                };
            },
            transformResponse: (response: ITransactionListResponse) => {
                return response.data.transactions || [];
            },
        }),

        getUserCardDetails: builder.query<IMakeDefaultCardResponse, string>({
            providesTags: [
                {
                    type: TAG_USER_CARD,
                    id: TAG_GET_USER_CARD,
                },
            ],
            query: (queryArgs) => {
                return {
                    url: `cardDetails/${queryArgs}`,
                    method: 'get',
                };
            },
        }),

        updatePaymentMethod: builder.mutation<IUserProfileResponse, IPaymentMethodRequest>({
            invalidatesTags: [
                {
                    type: TAG_USER_PROFILE,
                    id: TAG_GET_USER_PROFILE,
                },
            ],
            query: (body: IPaymentMethodRequest) => {
                return {
                    url: `/user/${body._id}`,
                    method: 'post',
                    body: body,
                };
            },
        }),

        generateXeroInvoice: builder.mutation<void, IXeroInvoiceGenerationRequest>({
            invalidatesTags: [
                {
                    type: TAG_TRANSACTION_TABLE,
                    id: TAG_GET_TRANSACTION_TABLE,
                },
            ],
            query: (body) => {
                return {
                    url: `/transactions/generateXero-invoice/${body.transactionId}`,
                    method: 'post',
                    body: {},
                };
            },
        }),

        // TODO: Remove any
        userPaymentSession: builder.query<IRetrievePaymentDetailsResponse, any>({
            query: (queryArgs) => {
                return {
                    url: `cardDetails/session-new?ps=${queryArgs.sessionId}&paymentMethods=${queryArgs.paymentMethods}`,
                    method: 'post',
                };
            },

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        spotdifApi.util.invalidateTags([
                            {
                                type: TAG_TRANSACTION_TABLE,
                                id: TAG_GET_TRANSACTION_TABLE,
                            },

                            {
                                type: TAG_USER_PROFILE,
                                id: TAG_GET_USER_PROFILE,
                            },
                        ]),
                    );
                } catch (error) {
                    console.log('Something went wrong with user payment session.', error.message);
                }
            },
        }),

        addCreditsManually: builder.mutation<IAddCreditsResponse, IAddCreditsRequest>({
            query: (body) => {
                return {
                    url: `/cardDetails/addCredits`,
                    method: 'post',
                    body,
                };
            },
        }),

        updateDefaultCard: builder.mutation<IMakeDefaultCardResponse, string>({
            invalidatesTags: [
                {
                    type: TAG_USER_CARD,
                    id: TAG_GET_USER_CARD,
                },
            ],
            query: (queryArgs) => {
                return {
                    url: `cardDetails/toggleForCard/${queryArgs}`,
                    method: 'post',
                };
            },
        }),

        deleteCard: builder.mutation<IMessageOnlyResponse, string>({
            invalidatesTags: [
                {
                    type: TAG_USER_CARD,
                    id: TAG_GET_USER_CARD,
                },
            ],
            query: (queryArgs) => {
                return {
                    url: `cardDetails/${queryArgs}`,
                    method: 'delete',
                };
            },
        }),

        getDownloadInvoicePdf: builder.query<any, string>({
            query: (queryArgs) => {
                return {
                    url: `leads/generatepdf/${queryArgs}`,
                    method: 'get',
                };
            },
        }),
    }),
});

export const {
    useGetTransactionsTableDataQuery,
    useGetUserCardDetailsQuery,
    useUpdatePaymentMethodMutation,
    useAddCreditsManuallyMutation,
    useUserPaymentSessionQuery,
    useUpdateDefaultCardMutation,
    useDeleteCardMutation,
    useGetDownloadInvoicePdfQuery,
    useLazyGetDownloadInvoicePdfQuery,
    useGenerateXeroInvoiceMutation,
} = creditAndBillingApi;

export default creditAndBillingApi;
