import React from 'react';
import useAuthentication from 'hooks/useAuthentication';
import { Form, useFormikContext } from 'formik';
import MDInput from 'components/themed/MDInput';
import { IAddCreditsRequest } from 'contracts/requests/IAddCreditsRequest';
import handleKeyPress from 'utils/validation';
import MDButton from 'components/themed/MDButton';
import CheckIcon from '@mui/icons-material/Check';
import MDBox from 'components/themed/MDBox';
import CurrencySymbol from 'components/shared/CurrencyFormat';

interface IBuyLeadSectionProps {
    isAddingCredits: boolean;
}

const BuyLeadManuallySection: React.FC<IBuyLeadSectionProps> = ({ isAddingCredits }) => {
    const VAT_AMOUNT = parseFloat(process.env.REACT_APP_VAT_AMOUNT) || 0.2;
    const { user } = useAuthentication();
    const { values, handleBlur, touched, errors, handleSubmit, handleChange } = useFormikContext<IAddCreditsRequest>();

    return (
        <>
            <Form onSubmit={handleSubmit} className="d-flex">
                <MDInput
                    type="text"
                    className="text-center"
                    onBlur={handleBlur}
                    name="amount"
                    placeholder="Enter number of leads"
                    onKeyPress={handleKeyPress}
                    value={values.amount}
                    onChange={handleChange}
                    inputProps={{ maxLength: 5 }}
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                />

                <MDButton
                    className="button button-solid-dark"
                    type="submit"
                    disabled={isAddingCredits}
                    isLoading={isAddingCredits}
                >
                    Confirm & Pay with default card
                    <CheckIcon className="ml-1" />
                </MDButton>
            </Form>
            <MDBox className="vat-div">
                {user?.currency !== 'EUR' && (
                    <div className="d-flex">
                        <p>
                            Total (exc. VAT) <span>Based on {`${values.amount || 0} * ${user?.leadCost} `}</span>
                        </p>
                        <p>
                            <CurrencySymbol currencyCode={user?.currency} />{' '}
                            {`${(Number(values.amount) * user?.leadCost).toFixed(2)}`}
                        </p>
                    </div>
                )}

                {user?.currency === 'EUR' ? (
                    <div className="d-flex">
                        <p>{`Total to pay`}</p>
                        <h6>
                            <span>
                                {' '}
                                <CurrencySymbol currencyCode={user?.currency} />{' '}
                                {`${(Number(values.amount) * user?.leadCost).toFixed(2)}`}
                            </span>
                        </h6>
                    </div>
                ) : (
                    <div className="d-flex">
                        <p>{`Total to pay (inc. VAT)`}</p>
                        <h6>
                            <span>
                                {' '}
                                <CurrencySymbol currencyCode={user?.currency} />{' '}
                                {`${(Number(values.amount) * user?.leadCost * (1 + Number(VAT_AMOUNT))).toFixed(2)}`}
                            </span>
                        </h6>
                    </div>
                )}
            </MDBox>
        </>
    );
};

export default BuyLeadManuallySection;
