import { IXeroDetailsResponse } from 'contracts/requests/IXeroDetailsResponse';
import { TAG_GET_XERO_DETAILS } from 'contracts/spotDiffAPITags';
import { spotdifApi } from '.';

const advanceManagementApi = spotdifApi.injectEndpoints({
    endpoints: (builder) => ({
        getXeroDetails: builder.query<IXeroDetailsResponse, string>({
            providesTags: [TAG_GET_XERO_DETAILS],
            query: (userId) => {
                return {
                    url: `/adminSettings/xero/contact/${userId}`,
                    method: 'GET',
                };
            },
        }),

        createXeroId: builder.mutation<any, string>({
            invalidatesTags: [TAG_GET_XERO_DETAILS],
            query: (userId) => {
                return {
                    url: `/adminSettings/xero/contact/${userId}`,
                    method: 'POST',
                };
            },
        }),
    }),
});

export const { useLazyGetXeroDetailsQuery, useCreateXeroIdMutation } = advanceManagementApi;
