import React from 'react';
import useAuthentication from 'hooks/useAuthentication';
import { useUpdatePaymentMethodMutation } from 'redux/services/spotdif/creditAndBilling';
import { enqueueSnackbar } from 'notistack';

const PaymentMethodSection: React.FC = () => {
    const { user } = useAuthentication();
    const [updatePaymentMethodTrigger] = useUpdatePaymentMethodMutation();

    const handlePaymentMethod = async (request) => {
        updatePaymentMethodTrigger(request)
            .unwrap()
            .then((res) => {
                enqueueSnackbar(res?.message, { variant: 'success', key: 'auto-charge' });
            })
            .catch((err) => {
                enqueueSnackbar(err.data?.error?.message, { variant: 'error' });
            });
    };

    return (
        <div className="payment-method-details">
            <h6>Payment Method</h6>

            <button
                className={`button ${
                    user?.paymentMethod === 'auto charge' ? 'button-solid' : 'button-outline-gray-light hover-text'
                } `}
                onClick={() => {
                    user?.paymentMethod !== 'auto charge' &&
                        handlePaymentMethod({ paymentMethod: 'auto charge', _id: user?._id });
                }}
            >
                auto Charge
            </button>

            <button
                className={`button ${
                    user?.paymentMethod === 'add credits manually' ? 'button-solid' : 'button-outline-blue'
                } `}
                onClick={() => {
                    user?.paymentMethod !== 'add credits manually' &&
                        handlePaymentMethod({ paymentMethod: 'add credits manually', _id: user?._id });
                }}
            >
                Buy manually
            </button>

            {/* <p className="or"> or</p>
                        <button
                            className="button button-ghost"
                            onClick={() => {
                                window.open('mailto:leads@nmg.group');
                            }}
                        >
                            Request weekly payment plan
                        </button> */}
        </div>
    );
};

export default PaymentMethodSection;
