/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import { OutlinedTextFieldProps, StandardTextFieldProps } from '@mui/material';
import classNames from 'classnames';

// Custom styles for MDInput
import MDInputRoot from 'components/themed/MDInput/MDInputRoot';
import { FC, forwardRef } from 'react';

// Declaring props types for MDInput
interface Props extends Omit<OutlinedTextFieldProps | StandardTextFieldProps, 'variant'> {
    variant?: 'standard' | 'outlined';
    error?: boolean;
    success?: boolean;
    disabled?: boolean;
    isBordered?: boolean;
    borderedWrapperClassConfig?: classNames.ArgumentArray,
}

const MDInput: FC<Props | any> = forwardRef(({
                                                 borderedWrapperClassConfig = {},
                                                 isBordered = true,
                                                 error,
                                                 success,
                                                 disabled = false,
                                                 ...rest
                                             }, ref) => (
    <>
        {isBordered ? (
            <div className={
                classNames(
                    'w-50',
                    borderedWrapperClassConfig,
                    {
                        'spotdif-outlined-field': true,
                        'spotdif-field': true,
                        // 'w-50': true,
                    },
                )
            }
            >
                <label>{rest.label}</label>
                {rest.labelWebHookUrl && (
                    <label>
                        <a href={rest.labelWebHookUrl} target="_blank" rel="noreferrer">
                            Click here
                        </a>{' '}
                        to see if your webhook URL integrates with your CRM.
                    </label>
                )}

                <MDInputRoot
                    {...rest}
                    className={classNames(rest.className, 'spotdif-outlined-field--input')}
                    label={null}
                    ref={ref}
                    ownerState={{ error, success, disabled }}
                    disabled={disabled}
                />
            </div>
        ) : (
            <MDInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} disabled={disabled} />
        )}
    </>
));

// Declaring default props for MDInput
MDInput.defaultProps = {
    error: false,
    success: false,
    disabled: false,
};

export default MDInput;
